import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/components/HomePage.vue'
import BookingForm from '@/components/BookingForm.vue'
import KayakTypeList from '@/components/KayakTypeList.vue'
import BookingConfirmation from '@/components/BookingConfirmation.vue'
import PaymentForm from '@/components/PaymentForm.vue'
import InfoPage from '@/components/InfoPage.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/yaks',
    name: 'KayakList',
    component: KayakTypeList
  },
  {
    path: '/book/:kayakTypeId/',
    name: 'TypedBookingForm',
    component: BookingForm,
    alias: '/book/'
  },
  {
    path: '/book/',
    name: 'KayaklessBookingForm',
    component: BookingForm
  },
  {
    path: '/booking/:bookingId/',
    name: 'BookingConfirmation',
    component: BookingConfirmation
  },
  {
    path: '/payment/:bookingId/',
    name: 'Payment',
    component: PaymentForm
  },
  {
    path: '/info/:page/',
    name: 'Info',
    component: InfoPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
