<template>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css">
    <div v-if="booking" class="container mx-auto p-4">
      <div class="bg-sky-800 text-gray-100 p-6 rounded-lg max-w-xl mx-auto">
        <div class="flex mb-4">
          <h1 class="text-xl font-bold playwrite-is mx-auto">Booking Confirmed</h1>
        </div>
        <p class="mb-6">Thank you for your booking! Here are the details of your reservation:</p>
        <div class="mb-4 booking-card">
          <h2 class="text-lg font-semibold mb-2">Booking Details</h2>
          <div>
            <label>Date</label>
            <i class="fas fa-calendar-alt text-xl"></i>
            <span>{{ booking.booking_date }}</span>
          </div>
          <div>
            <label>Time</label>
            <i class="fas fa-clock text-xl"></i>
            <span>{{ booking.booking_time.slice(0, 5) }}</span>
          </div>
          <div>
            <label>Kayak</label>
            <img :src="get_kayak_icon(kayakTypeName)" alt="Kayak Type" class="mx-auto">
            <span>{{ kayakTypeName }}</span>
          </div>
          <div>
            <label>Duration</label>
            <i class="fas fa-hourglass-half text-xl"></i>
            <span>{{ durationLabel }}</span>
          </div>
          <div>
            <label>Cost</label>
            <i class="fa-solid fa-file-invoice-dollar text-xl"></i>
            <span>{{ '$ ' + booking.total_price }}</span>
          </div>
          <div>
            <label>Status</label>
            <i class="fa-solid fa-receipt text-xl"></i>
            <span class="">{{ booking.status }}</span>
          </div>
        </div>
      </div>
    </div>
  </template>
  

<script>
import { reactive } from 'vue';
import axios from 'axios';

export default {
  setup() {
    const kayak_types = reactive({});

    return {
      kayak_types
    }
  },
  computed: {
    kayakTypeName() {
      return this.kayak_types[this.booking.kayak_type] ? this.kayak_types[this.booking.kayak_type].name : '';
    },
    durationLabel() {
        let duration = this.booking.duration_hours;
        if (duration <= 4) {
            return '4 Hours';
        } else if (duration <= 6) {
            return '6 Hours';
        } else if (duration <= 24) {
            return 'Full Day';
        } else {
            return String(Math.floor(duration/24)) + ' Days'
        }
            // '2 Days'
            // '3 Days'
            // '4 Days'
            // '5 Days'
    },
  },
  data() {
    return {
      booking: null,
    };
  },
  methods: {
    get_kayak_icon(kayak_type) {
        if (kayak_type === 'Single Yak') {
            return require('@/assets/icons/kayak.png');  
        } else {
            return require('@/assets/icons/kayak.png')
        }    
    },
    fetch_kayak_types() {
        axios.get('rentals/kayak-types/')
          .then(response => {
            for (let type of response.data){
              this.kayak_types[type.id] = type;
            }
          })
    },
    fetch_booking_details() {
      const booking_id = this.$route.params.bookingId;
      axios.get(`rentals/bookings/${booking_id}/`)
        .then(response => {
          this.booking = response.data;
        }).catch(function (error) {
            if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            }
            console.log(error.config);
        });
    },
  },
  mounted() {
    this.fetch_booking_details();
    this.fetch_kayak_types();
  }
};
</script>

<style scoped>
.container {
    max-width: 800px;
    margin: auto;
}
img {
    height: 1.4rem;
    width: 1.4rem;
    filter: brightness(0) saturate(100%) invert(100%) sepia(15%) saturate(628%) hue-rotate(176deg) brightness(99%) contrast(94%);
}
</style>
