<template>
  <div class="container mx-auto p-4 max-w-xl">
    <h1 class="text-2xl font-bold mb-4 playwrite-is text-sky-800">Book a Kayak</h1>
    <form class="booking-form bg-sky-800 p-5 rounded-lg group" novalidate @submit.prevent="openEmailModal">
      <span class="font-medium text-gray-100">Your Info</span>
      <div class="m-2 flex">
        <div class="w-1/2 px-1">
          <label for="name">Name</label>
          <input id="name" @change="$event.target.setCustomValidity('')" ref="customer_name" autocomplete="name" placeholder="Full Name" class="w-full rounded p-2" type="text" v-model="booking.customer_name" required pattern="^[a-zA-Z'.,]{1,} [ a-zA-Z'.,]{1,}[a-zA-Z' .,]{0,}$">
          <span class="field-error-message">Please enter your full name</span>
        </div>
        <div class="w-1/2 px-1">
          <label for="phone">Phone <span class="text-xs text-gray-100 italic">(optional)</span></label>
          <input id="phone" @input="acceptNumber" @change="$event.target.setCustomValidity('')" ref="customer_phone" placeholder="( ___ ) ___ - ____" autocomplete="tel" class="w-full rounded p-2" type="tel" v-model="booking.customer_phone" pattern="\([0-9]{3}\) [0-9]{3}-[0-9]{4}">
          <span class="field-error-message">Please enter a valid phone number</span>
        </div>
      </div>
      <div class="mx-2 mt-0 px-1">
          <label for="email">Email</label>
          <input id="email" placeholder="your@email.com" @change="$event.target.setCustomValidity('')" ref="customer_email" autocomplete="email" class="w-full rounded p-2" type="email" v-model="booking.customer_email" required pattern="[\-A-Za-z0-9._]+@[\-A-Za-z0-9.]+\.[A-Za-z]{2,}">
          <span class="field-error-message">Please enter a valid email address</span>
      </div>
      <hr class="h-1 mx-24 border-0 rounded my-4 bg-amber-300">
      <span class="font-medium text-gray-100">Your Booking</span>
      <div class="mx-2 mt-1 grid grid-cols-2 gap-4 px-1">
        <div class="col-span-1 grid content-start">
          <div class="my-1">
            <label for="booking_date">Booking Date</label>
            <input id="booking_date" class="block w-full rounded p-2" ref="booking_date_input" type="date" v-model="booking.booking_date" @change="updateMinTime" required>
          </div>
          <div class="my-1 sm:hidden">
            <label for="booking_time">Booking Time</label>
            <select id="booking_time" name="booking_time" class="block w-full rounded p-2" ref="booking_time_input" v-model="booking.booking_time" @change="update_duration" required>
              <option v-for="(time, index) in defaultTimes" :key="time" :value="time" :disabled="!isTimeAvailable(time)">{{ time }}</option>
            </select>
          </div>
          <div class="my-1">
            <label for="kayak_type">Kayak Type</label>
            <select id="kayak_type" class="block w-full rounded p-2" v-model="booking.kayak_type" required @change="updateTimes">
              <option v-for="type in kayakTypes" :key="type.id" :value="type.id">{{ type.name }}</option>
            </select>
          </div>
        </div>
        <div class="col-span-1">
          <div class="my-1 max-sm:hidden">
            <label for="booking_time">Booking Time</label>
            <select id="booking_time" name="booking_time" class="block w-full rounded p-2" ref="booking_time_input" v-model="booking.booking_time" @change="update_duration" required>
              <option v-for="(time, index) in defaultTimes" :key="index" :value="time" :disabled="!isTimeAvailable(time)">{{ time }}</option>
            </select>
          </div>
          <div class="my-2">
            <label>Duration</label>
            <div class="grid sm:grid-rows-3 sm:grid-flow-col mx-3 place-content-center justify-stretch text-gray-100">
              <div v-for="(label, index) in durationLabels" :key="index" class="flex my-1">
                <input name="duration" required :disabled="(index == 0 && !canBook(4)) || (index == 1 && !canBook(6))" @change="updateTimes" type="radio" :id="'duration_' + index" :value="index" v-model="selectedDurationIndex" class="accent-amber-400 mr-2 my-auto no-focus">
                <label class="w-7/8" :class="(index == 0 && !canBook(4)) || (index == 1 && !canBook(6)) ? 'line-through' : ''" :for="'duration_' + index">{{ label }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="h-1 mx-24 border-0 rounded mb-5 mt-1 bg-amber-300">
      <div class="m-2 grid grid-cols-2 gap-4 px-1">
        <div class="col-span-1">
          <label class="text-gray-100 text-center">Pick Up: <Transition name="fade" mode="out-in"><span :key="`${this.booking_date_display}${this.booking.booking_time}`" class="text-center bg-transparent text-gray-100 text-md font-bold">{{ this.booking_date_display }} @ {{ this.booking.booking_time }}</span></Transition></label>
          <label class="text-gray-100 text-center">Drop Off: <Transition name="fade" mode="out-in"><span :key="`${this.end_date_display}${this.end_time}`" class="text-center bg-transparent text-gray-100 text-md font-bold">{{ this.end_date_display }} @ {{ this.end_time }}</span></Transition></label>
        </div>
        <div class="col-span-1">
          <label class="block text-gray-100 text-center" for="total_price">Total Price:</label>
          <Transition name="fade" mode="out-in">
            <span id="total_price" :key="total_price" class="text-center bg-transparent text-gray-100 text-lg font-bold"> {{ total_price }} </span>
          </Transition>
        </div>
      </div>
      <div class="flex justify-between">
        <button type="submit" class="group-invalid:pointer-events-none group-invalid:opacity-50">Book</button>
        <button type="button" @click="openResetModal" class="w-1/4 bg-gray-200 text-gray-700 rounded hover:bg-gray-50">Reset</button>
      </div>

      <!-- Email Confirmation Modal -->
      <div v-if="showEmailModal" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
        <div class="bg-white p-6 rounded-lg shadow-lg max-w-sm mx-auto">
          <p class="text-xl font-semibold mb-2">Confirm Booking</p>
          <p>Your email is set as {{ this.booking.customer_email }}</p>
          <p>Make sure this is the correct email address</p>
          <div class="mt-2 flex justify-end">
            <button type="submit" @click="submitBooking" class="group-invalid:pointer-events-none group-invalid:opacity-30">Book</button>
            <button @click="closeEmailModal" class="bg-gray-200 text-gray-700 rounded transition ease-in-out hover:bg-gray-300 duration-300">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>
    <!-- Reset Confirmation Modal -->
    <div v-if="showResetModal" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
      <div class="bg-white p-6 rounded-lg shadow-lg max-w-sm mx-auto">
        <p class="text-xl font-semibold mb-2">Confirm Reset</p>
        <p>Are you sure you want to reset the form?</p>
        <div class="mt-2 flex justify-end">
          <button @click="resetForm">Reset</button>
          <button @click="closeResetModal" class="bg-gray-200 text-gray-700 rounded transition ease-in-out hover:bg-gray-300 duration-300">Cancel</button>
        </div>
      </div>
    </div>
</template>


<script>
import axios from 'axios';
import { reactive } from 'vue';

export default {
  data() {
    return {
      booking: {
        customer_name: '',
        customer_email: '',
        customer_phone: '',
        booking_date: '',
        booking_time: '10:00',
        kayak_type: '',
        duration_hours: 24,
      },
      kayakTypes: [],
      pricing: {},
      selectedDurationIndex: 2,
      defaultTimes: [],
      durationLabels: [
        '4 Hours',
        '6 Hours',
        'Full Day',
        '2 Days',
        '3 Days',
        '4 Days',
        // '5 Days',
        // Continue adding labels for additional days...
      ],
      durationHours: [
        4,
        6,
        24,
        48,
        72,
        96,
        // 120,
        // Continue adding hours for additional days...
      ],
      showModal: false,
      showResetModal: false,
      showEmailModal: false,
      times: reactive({pickup: [], dropoff: []}),
    };
  },
  computed: {
    available_times() {
      let times = Array.from(this.times.pickup)
      times = times.filter(t => this.times.dropoff.includes(t))
      return times
    },
    total_price() {
      if (this.selectedDurationIndex >= 0) {
        let total = '';
        let duration = this.getDurationLabel(this.selectedDurationIndex);
        if (this.selectedDurationIndex <= 3 && this.booking.kayak_type && this.pricing[this.booking.kayak_type]) {
          total = this.pricing[this.booking.kayak_type][duration] || '';
        } else if (this.selectedDurationIndex > 3 && this.booking.kayak_type && this.pricing[this.booking.kayak_type]) {
          let additionalDayPrice = this.pricing[this.booking.kayak_type]['add_d'];
          let additionalDays = this.selectedDurationIndex - 3;
          let basePrice = this.pricing[this.booking.kayak_type]['2d'];
          total = basePrice + (additionalDays * additionalDayPrice);
        }
        if (total !== '') {
          return '$ ' + String(total);
        }
      }
      return '...'
    },
    booking_date() {
      return new Date(this.booking.booking_date + 'T12:00')
    },
    booking_date_display() {
      return this.booking_date.toDateString().slice(0, -5)
    },
    end_date() {
      let end_date = new Date(this.booking_date.getTime());
      if (this.duration_hours >= 24) {
        end_date.setDate(end_date.getDate() + Math.floor(this.duration_hours/24))
      }
      return end_date
    },
    end_date_display() {
      return this.end_date.toDateString().slice(0, -5)
    },
    end_date_str() {
      let yyyy = this.end_date.getFullYear()
      let mm = String(this.end_date.getMonth() + 1).padStart(2, '0')
      let dd = String(this.end_date.getDate()).padStart(2, '0')
      return `${yyyy}-${mm}-${dd}`
    },
    end_time() {
      if (this.booking.booking_time !== '' && this.selectedDurationIndex !== '') {
        if (this.duration_hours < 24) {
          return this.sum_time_and_hours(this.booking.booking_time, this.duration_hours)
        }
        return this.booking.booking_time
      }
      return ''
    },
    duration_hours() {
      return this.durationHours[this.selectedDurationIndex]
    },
  },
  methods: {
    async updateTimes() {
      await axios.get(`rentals/kayak-times/${this.booking.kayak_type}/${this.booking.booking_date}`).then(
        (res) => {
          this.times.pickup = res.data
        }
      )
      if (this.duration_hours < 24) {
        this.times.dropoff = this.times.pickup
      } else if (this.booking.booking_date) {
        await axios.get(`rentals/kayak-times/${this.booking.kayak_type}/${this.end_date_str}`).then(
          (res) => {
            this.times.dropoff = res.data
          }
        )
      }
      if (this.booking.booking_time === '' || !this.isTimeAvailable(this.booking.booking_time)) {
        this.booking.booking_time = this.available_times[Math.min(3, this.available_times.length)]
      }
    },
    sum_time_and_hours(time, hours) {
      let start = String(time).split(':')
      return `${String(Number(start[0]) + hours).padStart(2, '0')}:${start[1]}`
    },
    update_duration() {
      if (this.duration_hours < 24 && !this.canBook(this.duration_hours)) {
        this.selectedDurationIndex = 2;
      }
    },
    isTimeAvailable(time) {
      return this.times.pickup.includes(time) && this.times.dropoff.includes(time)
    },
    canBook(hours) {
      if (this.booking.booking_time != '') {
        if ((hours === 4 && this.booking.booking_time > "16:00") || (hours === 6 && this.booking.booking_time > "14:00")) {
          return false
        }
        if (hours < 24) {
          return this.times.pickup.includes(this.sum_time_and_hours(this.booking.booking_time, hours))
        }
      }
      return true
    },
    acceptNumber() {
        var x = this.booking.customer_phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.booking.customer_phone = !x[2] ? (x[1].length > 0 ? '(' + x[1] : x[1]) : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    submitBooking() {
      this.booking.duration_hours = this.duration_hours;
      axios.post('rentals/bookings/', this.booking)
        .then(response => {
          this.$router.push({ name: 'Payment', params: { bookingId: response.data.id } })
        }).catch(error => {
          if (error.response) {
            this.closeEmailModal();
            if (error.response.status === 400) {
              if (error.response.data['customer_name']) {
                this.$refs.customer_name.setCustomValidity(true);
              } 
              if (error.response.data['customer_phone']) {
                this.$refs.customer_phone.setCustomValidity(true);
              }
              if (error.response.data['customer_email']) {
                this.$refs.customer_email.setCustomValidity(true);
              }
            }
          }
        })
    },
    fetchKayakTypes() {
      axios.get('rentals/kayak-types/')
        .then(response => {
          this.kayakTypes = response.data;
        });
    },
    fetchPricing() {
      axios.get('rentals/pricing/')
        .then(response => {
          this.pricing = response.data.reduce((acc, price) => {
            let kayak_type = String(price.kayak_type);
            if (!acc[kayak_type]) {
              acc[kayak_type] = {};
            }
            acc[kayak_type][price.duration] = parseFloat(price.price);
            return acc;
          }, {});
        });
    },
    getDurationLabel(index) {
      switch (index) {
        case 0: return '4h';
        case 1: return '6h';
        case 2: return '1d';
        case 3: return '2d';
        default: return 'add_d';
      }
    },
    resetForm() {
      this.booking = {
        customer_name: '',
        customer_email: '',
        customer_phone: '',
        booking_date: '',
        booking_time: '10:00',
        duration_hours: 24,
        kayak_type: this.booking.kayak_type,
      };
      this.selectedDurationIndex = 2;
      this.updateDateAndTimeLimitsAndDefaults();
      this.closeResetModal();
      this.updateTimes()
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    openResetModal() {
      this.showResetModal = true;
    },
    closeResetModal() {
      this.showResetModal = false;
    },
    openEmailModal() {
      this.showEmailModal = true;
    },
    closeEmailModal() {
      this.showEmailModal = false;
    },
    updateMinTime() {
      this.updateTimes()
      let minTime = '06:00';
      let maxTime = '20:00';
      
      let today = new Date();
      let bookingDate = new Date(this.booking.booking_date + 'T12:00');
      
      if (today.toDateString() === bookingDate.toDateString()) {
        let minHour = Math.max(6, today.getHours() + 3);
        minTime = `${String(minHour).padStart(2, '0')}:00`;
      }

      this.$refs.booking_time_input.setAttribute('min', minTime);
      this.$refs.booking_time_input.setAttribute('max', maxTime);

      this.update_duration()
    },
    updateDateAndTimeLimitsAndDefaults() {
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm = String(today.getMonth() + 1).padStart(2, '0');
      let dd = String(today.getDate()).padStart(2, '0');
      const minDate = `${yyyy}-${mm}-${dd}`;

      today.setDate(today.getDate() + 1);
      yyyy = today.getFullYear();
      mm = String(today.getMonth() + 1).padStart(2, '0');
      dd = String(today.getDate()).padStart(2, '0');
      this.booking.booking_date = `${yyyy}-${mm}-${dd}`;
      this.booking.booking_time = '10:00';
      
      this.$refs.booking_date_input.setAttribute("min", minDate);
      this.$refs.booking_date_input.setAttribute("max", '2024-10-31');
      this.$refs.booking_time_input.setAttribute("step", '1100');

      axios.get('rentals/kayak-times').then(
        (res) => {
          this.defaultTimes = res.data
        }
      )
    }
  },
  mounted() {
    this.fetchKayakTypes();
    this.booking.kayak_type = this.$route.params.kayakTypeId || 1;
    this.updateTimes();
    this.fetchPricing();
    this.updateDateAndTimeLimitsAndDefaults();
    this.updateMinTime();
  }
};
</script>
<style>

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>