<template>
    <div class="container mx-auto p-4">
        <header class="text-center mb-3">
        </header>
        <h1 class="text-2xl font-bold text-sky-800 mb-3 playwrite-is capitalize">{{ this.page_data.name }}</h1>
        <div class="text-gray-50 text-lg mx-auto w-72 bg-sky-800 rounded-lg">
            <p v-if="this.page_data.is_html" v-html="this.page_data.text"></p>
            <p v-else>{{ this.page_data.text }}</p>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios'

export default {
    data() {
        return {
            page_data: {
                name: '',
                text: '',
            },
        }
    },
    mounted() {
        let page = this.$route.params.page || 'about'
        axios.get(`content/pages/${page}`).then((res) => {
            this.page_data = res.data
        })
    },
}
</script>