<template>
  <div class="container mx-auto p-4">
    <header class="text-center mb-3">
      <!-- <img src="@/assets/images/pov_kayak.jpeg" class="ring-amber-600 ring-2 my-3 mx-auto w-96 h-12 object-cover object-top"> -->
      <h1 class="text-4xl font-bold text-sky-800 mb-3 playwrite-is">Welcome!</h1>
      <p class="text-lg text-gray-600">Explore the beauty of nature with our high-quality kayaks!</p>
    </header>
    <div class="flex">
      <div class="relative mx-auto h-72 w-72">
        <img class="absolute top-0 left-0 border-sky-800 rounded-lg border-4 mx-auto h-full w-full object-cover from-emerald-300 bg-gradient-to-t to-sky-300">
        <img src="@/assets/images/pov_kayak.jpeg" onload="this.style.opacity=1" class="aspect-square mx-auto h-full w-full object-cover absolute top-0 left-0 border-sky-800 rounded-lg border-4 from-emerald-300 bg-gradient-to-t to-sky-300 opacity-0 transition-all duration-400 ease-in-out">
      </div>
    </div>
    <div class="flex mx-auto w-72">
      <router-link to="/yaks" class="button w-full py-3 rounded-lg transition ease-in-out duration-300">Book Now</router-link>
    </div>
  </div>
</template>
