<template>
  <div>
    <nav class="">
      <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <router-link to="/" class="nav-logo">
            <!-- <img src="https://flowbite.com/docs/images/logo.svg" class="h-8" alt="Flowbite Logo" /> -->
            <h1>Yaks 2 Go</h1>
        </router-link>
        <div class="block w-auto">
          <ul class="flex flex-row rtl:space-x-reverse">
            <li v-for="link in links">
              <router-link :to="link.path" class="nav-link capitalize">{{ link.name }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <suspense>
      <router-view/>
      <template #fallback>
        Loading...
      </template>
    </suspense>
  </div>
  <footer class="mx-auto buttom-0">
    <div class="footer">
      <span class="sm:text-center pr-5">© 2024 Yaks 2 Go All Rights Reserved.</span>
      <ul class="flex flex-wrap items-center mt-3 font-medium md:mt-0">
          <li v-for="link in footer_links">
              <router-link :to="link.path" class="capitalize">{{ link.name }}</router-link>
          </li>
      </ul>
    </div>
    <div class="h-2"></div>
  </footer>
</template>

<script setup>
import LogRocket from 'logrocket';
import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_KAYAK_RENTAL_API_BASE_URL

if (process.env.VUE_APP_MODE === "PROD"){
  axios.defaults.xsrfHeaderName = "X-CSRFToken"
  axios.defaults.xsrfCookieName = "csrftoken"
  axios.defaults.withCredentials = true
  LogRocket.init('5x97re/yaks');
}

const links = [
  {path: "/", name: "home"},
  {path: "/yaks", name: "yaks"},
  {path: "/book", name: "book"},
]

const footer_links = [
  {path: {name: 'Info', params: { page: 'about' }}, name: "about"},
  {path: {name: 'Info', params: { page: 'policies' }}, name: "policies"},
  {path: {name: 'Info', params: { page: 'licensing' }}, name: "licensing"},
  {path: {name: 'Info', params: { page: 'contact' }}, name: "contact"},
]

</script>