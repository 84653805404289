<template>
    <div class="container mx-auto p-4">
      <h1 class="text-2xl font-bold mb-4 playwrite-is text-sky-800">Yak Types</h1>
      <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 justify-center">
        <router-link :to="{ name: 'TypedBookingForm', params: { kayakTypeId: kayakType.id } }" tag="div" v-for="kayakType in kayakTypes" :key="kayakType.id" class="kayak-card transition-width">
            <h2 class="text-xl font-semibold playwrite-is">{{ kayakType.name }}</h2>
            <ul class="list-none pl-0">
            <li v-for="perk in parseDescription(kayakType.description)" :key="perk" class="flex mt-2 item-center text-left">
                <img :src="getIcon(perk)" alt="" class="w-6 h-6 mr-2 my-auto"/>
                <span class="my-auto">{{ perk }}</span>
            </li>
            </ul>        
            <router-link v-if="isMobile() || (windowWidth <= 640)" :to="{ name: 'TypedBookingForm', params: { kayakTypeId: kayakType.id } }" class="">
            Book Now
            </router-link>
        </router-link>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'

  export default {
    data() {
      return {
        kayakTypes: [],
        windowWidth: 0,
      }
    },
    mounted() {
      this.onResize()
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })
    },
    beforeUnmount() { 
      window.removeEventListener('resize', this.onResize); 
    },
    created() {
      this.fetchKayakTypes()
    },
    methods: {
      onResize() {
        this.windowWidth = window.innerWidth
      },
      isMobile() {
        if(window.matchMedia("(pointer: coarse)").matches) {
          return true;
        }
        return false
      },
      fetchKayakTypes() {
        axios.get('rentals/kayak-types/')
          .then(response => {
            this.kayakTypes = response.data
          })
      },
      parseDescription(description) {
        return description.split(', ')
      },
      getIcon(perk) {
        const icons = {
          'sit on top single kayak and paddles': require('@/assets/icons/single_kayak.png'),
          'sit on top double kayak and two paddles': require('@/assets/icons/double_kayak.png'),
          'universal soft car roof rack': require('@/assets/icons/roof_rack.png'),
          'life vest (PFD)': require('@/assets/icons/life_vest.png'),
          'dry bag': require('@/assets/icons/dry_bag.png'),
          'pump': require('@/assets/icons/pump.png'),
          'inflatable paddle board and a paddle': require('@/assets/icons/paddle_board.png'),
        }
        return icons[perk] || ''
      }
    }
  }
  </script>
  
  <style scoped>
  img {
    height: 2.4rem;
    width: 2.4rem;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(652%) hue-rotate(325deg) brightness(117%) contrast(100%);
  }
  li:nth-child(3) img {
    height: 2.1rem;
    width: 2.1rem;
    margin-left: 0.15rem;
    margin-right: 0.65rem;
  }
  li:last-child img {
    height: 3.2rem;
    width: 3.2rem;
    margin-left: -0.35rem;
    margin-right: 0.15rem;
  }
  </style>
  