<template>
  <div class="container mx-auto p-4 max-w-xl">
    <h1 class="text-2xl text-sky-800 font-bold mb-4 playwrite-is">Payment</h1>
    <form class="booking-form bg-sky-800 p-5 rounded-lg shadow" @submit.prevent="submitPayment">
      <StripeElements
        v-if="stripeLoaded"
        v-slot="{ elements, instance }"
        ref="elms"
        :stripe-key="stripeKey"
        :instance-options="instanceOptions"
        :elements-options="elementsOptions"
        class="grid m-5">
        <StripeElement
          ref="payment"
          type="payment"
          :elements="elements"
          :options="paymentOptions"/>
      </StripeElements>
      <button type="submit" class="pay-button">Pay{{ (total) ? ` $${total}` : '' }}</button>
    </form>
  </div>
</template>
  
<script>
  import { ref, onBeforeMount } from 'vue'
  import { useRoute } from 'vue-router'
  import { loadStripe } from '@stripe/stripe-js'
  import { StripeElements, StripeElement } from 'vue-stripe-js'

  
  export default {
    async setup() {
      onBeforeMount(() => {
        const stripePromise = loadStripe(stripeKey.value)
        stripePromise.then(() => {
          stripeLoaded.value = true
        })
      })
      function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      }
      const route = useRoute()
      const booking_id = route.params.bookingId;
      const res = await fetch(`${process.env.VUE_APP_KAYAK_RENTAL_API_BASE_URL}rentals/create-payment-intent/`, {
        method: "POST",
        headers: { "Content-Type": "application/json", "X-CSRFToken": getCookie("csrftoken") },
        body: JSON.stringify({booking_id}),
        credentials: 'same-origin',
      })
      const intent_response = await res.json();
      const stripeKey = ref(process.env.VUE_APP_STRIPE_KEY) // test key
      const instanceOptions = ref({
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      })
      const elementsOptions = ref({
        clientSecret: intent_response.clientSecret,
        appearance: {
          theme: 'stripe',
          variables: {
            borderRadius: '4px',
            colorPrimary: 'rgb(252 211 77)',
            colorDanger: 'rgb(252 211 77)',
            focusOutline: 'transparent',
            focusBoxShadow: 'none',
            // fontLineHeight: '1.5',
          },
          rules: {
            '.Tab, .Input': {
              'color': '#000000',
            },
            '.Label': {
              'color': '#FAFAFA',
            },
            '.Input:focus': {
              'border': 'solid 2px rgb(252 211 77)',
            },
            '.Input': {
              padding: '12px'
            },
            '.Input--invalid': {
              color: "lightblack"
            },
            '.LinkOptIn-labelText': {
              color: "#FAFAFA",
            },
          }
        },
        // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
      })
      const paymentOptions = ref({

      })
      const stripeLoaded = ref(false)
      const payment = ref()
      const elms = ref()

      return {
        stripeKey,
        stripeLoaded,
        instanceOptions,
        elementsOptions,
        paymentOptions,
        payment,
        elms,
        total: intent_response.total,
      }

    },
    components: {
      StripeElements,
      StripeElement,
    },
    methods: {
      async submitPayment() {
        let booking_id = this.$route.params.bookingId;
        this.elms.elements.submit()
        this.elms.instance.confirmPayment({
          clientSecret: this.elementsOptions.clientSecret,
          elements: this.elms.elements,
          confirmParams: {
            return_url: `${window.location.origin}/booking/${booking_id}`,
          },
        }).then(function(result) {
          console.log(result);
          if (result.error) {
            // Inform the customer that there was an error.
          }
        });
      }
    }
  };
</script>